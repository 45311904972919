@import '../util.scss';

.table {
  width: 100%;
}

.tr {
  &:hover {
    background: rgba(255,255,255,0.05);
  }
}

.th {
  border-bottom: 2px solid rgba(81, 81, 81, 1);
  text-align: left;
  padding: ln(2) ln(2);
  font-weight: 600;
}

.td {
  border-bottom: 1px solid rgba(81, 81, 81, 1);
  padding: ln(2) ln(2);
  vertical-align: middle;
}

.tr:last-child {
  .td {
    border-bottom: none;
  }
}

.noRows {
  text-align: center;
  font-style: italic;
  color: $gray-500;
}

.tableLink {
  display: inline-flex;
  align-items: center;
  color: $blue-500;
  text-decoration: underline;

  svg {
    margin-left: ln(1);
    width: ln(3);
    height: ln(3);
  }
}

.paginationCont {
  @include flex($jc: space-between, $ai: center);
  padding: ln(2);
  width: 100%;

  .buttonSpacer {
    @include space-x(ln(2));
  }
}