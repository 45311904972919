@import '../util.scss';

.container {
  background-color: $gray-800;
  display: inline-block;
  border-radius: $rounded-sm;
  outline: 1px solid $gray-700;
}

.button {
  font-size: $text-base;
  border-radius: $rounded-sm !important;
  padding: 6px 14px;
  height: auto;
  border: none !important;

  &:not(:first-child):not(:last-child) {
    margin-left: 1px;
    margin-right: 1px;
  }

  &:last-child:nth-child(2) {
    margin-left: 1px;
  }

  &:hover {
    background-color: $gray-900 !important  ;
  }
}

.active {
  outline: 1px solid $white;
  border: none !important;
  background-color: $gray-900;
}