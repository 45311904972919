@import '../util.scss';

$padding: 10px;

.card {
  @include timing-function;
  transition-property: filter;
  background-color: #1F1F1F;
  color: $white;  
  width: 100%;
  overflow: hidden;
  filter: $shadow-md;

  &.noPad {
    padding: 0 !important;
  }

  &.hover:hover {
    filter: $shadow-md;
    cursor: pointer;
  }
}

.title {
  display: flex;
  align-items: center;
  padding: $padding;
  border-bottom: 1px solid $gray-300;
  background: $white;

  .icon {
    margin-right: 7px;
    width: 26px;
    height: 26px;
  }

  .content {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: $text-base;
    font-weight: 600;
  }

  &.bordered {
    border-bottom: 1px solid $gray-300;
    padding: $padding;
  }
}

.body {
  padding: $padding;

  &.noPad {
    padding: 0;
  }
}

.footer {
  padding: $padding;
  @include flex($ai: center, $jc: flex-end);
  @include space-x(ln(2));
}