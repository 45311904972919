@import '../util.scss';

.unstyled {}

.button {
  @include transition-colors;
  padding: 6px 9px;
  font-size: $text-base;
  font-weight: 500;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  user-select: none;
  display: inline-flex;
  align-items: center;
  min-height: 38px;

  .content {
    display: inline-flex;
    align-items: center;
    height: 100%;
    white-space: nowrap;
    justify-content: space-between;

    &:only-child {
      margin: 0 auto;
    }
  }

  .icon {
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }

  .spinner {
    @include inset;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &[disabled] {
    cursor: initial;
  }

  &.loading>.content {
    opacity: 0;
  }

  &.blue {
    color: $white;
    background-color: $blue-500;
    border: 1px solid $blue-600;

    &:hover:not([disabled]) {
      background-color: $blue-600;
    }

    &[disabled]:not(.loading) {
      background-color: $blue-400;
      border: 1px solid $blue-500;
    }
  }

  &.yellow {
    color: $black;
    background-color: $yellow-400;
    border: 1px solid $yellow-500;

    &:hover:not([disabled]) {
      background-color: $yellow-500;
    }

    &[disabled]:not(.loading) {
      background-color: $yellow-300;
      border: 1px solid $yellow-400;
    }
  }

  &.red {
    color: $white;
    background-color: $red-500;
    border: 1px solid $red-600;

    &:hover:not([disabled]) {
      background-color: $red-600;
    }

    &[disabled]:not(.loading) {
      background-color: $red-400;
      border: 1px solid $red-500;
    }
  }

  &.green {
    color: $white;
    background-color: $green-500;
    border: 1px solid $green-600;

    &:hover:not([disabled]) {
      background-color: $green-600;
    }

    &[disabled]:not(.loading) {
      background-color: $green-400;
      border: 1px solid $green-500;
    }
  }

  &.gray {
    color: $black;
    background-color: $gray-200;
    border: 1px solid $gray-400;

    &:hover:not([disabled]) {
      background-color: $gray-200;
    }

    &[disabled]:not(.loading) {
      background-color: $gray-100;
      border: 1px solid $gray-300;
    }
  }

  &.white {
    color: $black;
    background-color: $white;
    border: 1px solid $gray-200;

    &:hover:not([disabled]) {
      background-color: $gray-100;
    }
  }

  &.borderless {
    &:hover:not([disabled]) {
      background-color: $gray-200;
    }
  }

  &.rounded {
    border-radius: $rounded-full;
    min-width: 42px;

    .content {
      justify-content: center;
    }

    .icon {
      margin-right: 0;
    }

    &:hover:not([disabled]) {
      background-color: $gray-200;
    }
  }

  &.small {
    padding: 4px 6px;
    font-size: $text-sm;
    height: 32px;
    min-width: 32px;
    min-height: auto;
  }

  &.tiny {
    padding: 4px 2px;
    font-size: $text-sm;
    height: 24px;
    min-width: 24px;
    min-height: auto;

    .icon {
      width: 18px;
      height: 18px;
      margin-right: 0;
    }
  }

  &.microscopic {
    padding: 0;
    min-height: auto;

    .icon {
      width: 15px;
      height: 15px;
    }
  }

  &.noBorder {
    border: none !important;
  }
}