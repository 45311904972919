@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  display: block;
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

h2 {
  display: block;
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

h3 {
  display: block;
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

h4 {
  display: block;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

h5 {
  display: block;
  font-size: .83em;
  margin-top: 1.67em;
  margin-bottom: 1.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

h6 {
  display: block;
  font-size: .67em;
  margin-top: 2.33em;
  margin-bottom: 2.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.messageCont {
  padding: 0 16px 0 40px;
}

.messageBox {
  position: relative;
}

.messageBox:not(:first-child)::before {
  content: '';
  z-index:1;
  width: 2px;
  background: rgba(229, 231, 235, 0.4);
  position: absolute;
  top: 0;
  bottom: -37px;
  left: -23px;
}

.messageBox::after {
  content: '';
  z-index:2;
  width: 10px;
  height: 10px;
  background: rgb(59, 122, 216);
  border-radius: 100%;
  position: absolute;
  top: 0;
  left: -27px;
}

.commentBox {
  position: relative;
}

.commentBox:not(:first-child)::before {
  content: '';
  z-index:1;
  width: 2px;
  background: rgba(229, 231, 235, 0.4);
  position: absolute;
  top: 0;
  bottom: -25px;
  left: -23px;
}

.commentBox::after {
  content: '';
  z-index:2;
  width: 10px;
  height: 10px;
  background: rgb(174, 176, 179);
  border-radius: 100%;
  position: absolute;
  top: 0;
  left: -27px;
}


.statusBox {
  position: relative;
}

.statusBox:not(:first-child)::before {
  content: '';
  z-index:1;
  width: 2px;
  background: rgba(229, 231, 235, 0.4);
  position: absolute;
  top: 12px;
  bottom: -35px;
  left: -22px;
}

.statusBox::after {
  content: '';
  z-index:2;
  width: 10px;
  height: 10px;
  background: rgb(122, 201, 119);
  border-radius: 100%;
  position: absolute;
  top: calc(50% - 6px);
  left: -26px;
}