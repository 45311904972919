@import 'https://fonts.googleapis.com/css?family=Montserrat:300,400,700';

html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  overflow: scroll;
  overflow-x: hidden;
}

.MuiBox-root {
  height: 100%;
  align-items: center;
  margin: 0px;
  padding: 0px !important;
}

.container {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.rwd-table {
  margin: 1em 0;
  min-width: 300px;
  width: '100%';
  background: #34495e;
  color: #fff;
  border-radius: 0.4em;
  overflow: hidden;
  text-align: center;
}

.rwd-table tr {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-color: lighten(#34495e, 10%);
  text-align: center;
}

.rwd-table th,
.rwd-table td {
  text-align: left;
  margin: 0.5em 1em;
  text-align: center;
}

.rwd-table th {
  display: none;
}

.rwd-table td {
  display: block;
}

.rwd-table th,
.rwd-table td {
  display: table-cell;
  padding: 0.25em 0.5em;
}

.rwd-table th:first-child,
.rwd-table td:first-child {
  padding-left: 0;
}

.rwd-table th:last-child,
.rwd-table td:last-child {
  padding-right: 0;
}

.rwd-table th,
.rwd-table td {
  padding: 1em !important;
}
.rwd-table td:first-child {
  padding-top: 0.5em;
}

.rwd-table td:last-child {
  padding-bottom: 0.5em;
}
