.gauge-wrapper {
  display: inline-block;
  width: auto;
  margin: 0 auto;
  padding: 20px 15px 15px;
}

.gauge {
  background: #e7e7e7;
  box-shadow: 0 -3px 6px 2px rgba(0, 0, 0, 0.5);
  width: 400px;
  height: 200px;
  border-radius: 200px 200px 0 0 !important;
  position: relative;
  overflow: hidden;
}
.gauge.min-scaled {
  transform: scale(0.5);
}

.gauge-center {
  content: '';
  color: #fff;
  width: 60%;
  height: 60%;
  background: #15222e;
  border-radius: 200px 200px 0 0 !important;
  position: absolute;
  box-shadow: 0 -13px 15px -10px rgba(0, 0, 0, 0.28);
  right: 21%;
  bottom: 0;
  color: #fff;
  z-index: 10;
}

.gauge-center .label,
.gauge-center .number {
  display: block;
  width: 100%;
  text-align: center;
  border: 0 !important;
}
.gauge-center .label {
  font-size: 1.5em;
  opacity: 0.6;
  margin: 1.1em 0 0.3em 0;
}
.gauge-center .number {
  font-size: 2em;
}

.needle {
  width: 160px;
  height: 14px;
  background: #15222e;
  border-bottom-left-radius: 100% !important;
  border-bottom-right-radius: 5px !important;
  border-top-left-radius: 100% !important;
  border-top-right-radius: 5px !important;
  position: absolute;
  bottom: -4px;
  left: 40px;
  transform-origin: 100% 4px;
  transform: rotate(0deg);
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.38);
  display: none;
  z-index: 9;
}

.four.rischio1 .needle {
  animation: fourspeed1 2s 1 both;
  animation-delay: 1s;
  display: block;
}
.four.rischio2 .needle {
  animation: fourspeed2 2s 1 both;
  animation-delay: 1s;
  display: block;
}
.four.rischio3 .needle {
  animation: fourspeed3 2s 1 both;
  animation-delay: 1s;
  display: block;
}
.four.rischio4 .needle {
  animation: fourspeed4 2s 1 both;
  animation-delay: 1s;
  display: block;
}

.slice-colors {
  height: 100%;
}

.slice-colors .st {
  position: absolute;
  bottom: 0;
  width: 0;
  height: 0;
  border: 100px solid transparent;
}

.gauge {
  background: conic-gradient(
    from -90deg at 50% 100%,
    #e84c3d 0deg 153deg,
    /* Red: 0-85% */ #e67e22 153deg 171deg,
    /* Orange: 85-95% */ #f1c40f 171deg 176.4deg,
    /* Yellow: 95-98% */ #1eaa59 176.4deg 180deg /* Green: 98-100% */
  );
}

@-webkit-keyframes fourspeed1 {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(16deg);
  }
}

@-webkit-keyframes fourspeed2 {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(65deg);
  }
}

@-webkit-keyframes fourspeed3 {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(115deg);
  }
}

@-webkit-keyframes fourspeed4 {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(164deg);
  }
}
