.select-truncate {
  width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px; /* Add padding around the icon */
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-item-typography {
  font-size: 0.75rem;
  white-space: nowrap;
}
.table-container .MuiTableCell-root {
  border: 1px solid #444;
}

.select-truncate .MuiSelect-select {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.menu-item-typography {
  color: white;
}

.MuiSelect-select.MuiSelect-select {
  color: white;
  background-color: #555;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.MuiOutlinedInput-root {
  border: 1px solid #555;
}

.MuiOutlinedInput-input {
  padding: 8px;
  color: white;
  background-color: #333;
  text-align: center;
}

/* Add this CSS to your styles.css or equivalent file */
.inputLabelFocused {
  color: white !important; /* Adjust the color as needed */
}

.notchedOutline {
  border-width: '1px'; /* Make the border more visible */
  border-color: '#FFFFFF' !important; /* Adjust the border color */
}

/* styles.css */
.table-container .MuiTableCell-root {
  padding: 10px;
}

.table-container .MuiButton-root {
  margin: 0 5px;
}

.table-container .actions-cell {
  display: flex;
  justify-content: center;
  align-items: center;
}

.save-button {
  background-color: #1976d2;
  color: white;
}

.remove-button {
  background-color: #d32f2f;
  color: white;
}

/* Chat Popup Styling */
.chat-popup {
  position: fixed;
  bottom: 100px;
  max-height: 50vh;
  overflow-y: auto;

  right: 20px;
  width: 350px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.chat-header {
  background-color: #007bff;
  padding: 10px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat-header button {
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.chat-container {
  padding: 10px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.chat-history {
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 10px;
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 5px;
}

.chat-bubble {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  max-width: 70%;
}

.chat-bubble.user {
  background-color: #007bff;
  color: white;
  text-align: right;
  margin-left: auto;
  align-self: flex-end;
}

.chat-bubble.ai {
  background-color: #e9e9e9;
  color: black;
  text-align: left;
  align-self: flex-start;
}

.chat-input {
  display: flex;
}

.chat-input input {
  flex: 1;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
}

.chat-input button {
  padding: 10px 15px;
  margin-left: 10px;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.chat-input button:hover {
  background-color: #0056b3;
}
